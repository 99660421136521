@media only screen and (max-width: 1200px) {
	.cart-btn-reservar {
		width: 100%;
		margin-left: 0 !important;
	}
}

@media only screen and (min-width: 100px) and (max-width: 699px) {
	.cart-actions-direction {
		flex-direction: column !important;
		width: 100%;
	}

	.cart-btn {
		margin-left: 0 !important;
	}
}

.cart-btn {
	flex: auto;
}

.cart-actions-direction {
	flex-direction: row;
}

.cart-actions-container > :not(:first-child) {
    margin-left: 8px;
}

.cart-actions-container {
	width: 100%;
	display: flex;
	flex-flow: wrap;
	justify-content: space-between;
	margin: 0 !important;
}
