.img-logo {
	width: 100%;
}

.main-div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.coming-soon .app-login-main-content {
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0) !important;
	background-color: #fff0 !important;
}

.coming-soon .app-logo-content {
	background-color: #fff0 !important;
}

.app-login-content {
	margin-top: 20px;
}

.title-coming-soon {
	color: black !important;
    font-size: 40px !important;
	font-weight: 500 !important;
	text-align: center !important;
}

.title-coming-soon-2 {
	color: black !important;
    font-size: 25px !important;
	font-weight: 500 !important;
	text-align: center !important;
}