.link-auth{
	color:#2196f3 !important;
	cursor: pointer;
}

#site_ciudades{
	width: 192px;
}

.checkbox-register .MuiFormControlLabel-label{
	font-size: 15px !important;
}

#rc-imageselect {transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;}