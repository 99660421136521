@media only screen and (min-width: 1040px) {
	.textItemCartMiniItem {
		width: 110px !important;
	}
}

@media only screen and (min-width: 1020px) and (max-width: 1039px) {
	.textItemCartMiniItem {
		width: 100px !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1019px) {
	.textItemCartMiniItem {
		width: 90px !important;
	}
}

@media only screen and (min-width: 800px) and (max-width: 991px) {
	.textItemCartMiniItem {
		width: 100% !important;
	}
}

@media only screen and (min-width: 700px) and (max-width: 799px) {
	.textItemCartMiniItem {
		width: 700px !important;
	}
}

@media only screen and (min-width: 600px) and (max-width: 699px) {
	.textItemCartMiniItem {
		width: 350px !important;
	}
}

@media only screen and (min-width: 500px) and (max-width: 599px) {
	.textItemCartMiniItem {
		width: 250px !important;
	}
}

@media only screen and (min-width: 400px) and (max-width: 499px) {
	.textItemCartMiniItem {
		width: 150px !important;
	}
}

@media only screen and (min-width: 100px) and (max-width: 399px) {
	.textItemCartMiniItem {
		width: 50px !important;
	}
}