.dialog-notification .MuiDialog-container .MuiPaper-root{
	border-radius: 10px !important;
}

.notification-modal .MuiDialog-paperWidthSm{
	border-radius: 10px;
	padding: 20px !important;
}

.notification-modal .app-logo-content{
	padding: 20px 35px 20px;
}

.notification-modal .app-login-content{
	padding: 23px 0px 0px;
}